import * as React from 'react'
import { Trans } from '@lingui/macro'
import { Section } from '../../lib/components/section'
import { Loading } from '../../lib/components/loading'

import {
    useReleaseMoreRecommendationsLazyQuery,
    ReleaseRecommendationsFragment,
    ReleaseMoreRecommendationsQuery,
} from '../../api/types'
import { addState, Dispatcher } from '../../lib/add-state'
import { RecommendationsUI } from './ui'

type AsyncProps = {
    discogsId: number
    recommendations?: NonNullable<ReleaseRecommendationsFragment['recommendations']> | null | undefined
    isOffensive?: boolean | null | undefined
    disableLoadingMore?: boolean
}

export type Props = {
    discogsId: number
    recommendations: NonNullable<ReleaseRecommendationsFragment['recommendations']>
    isOffensive: boolean | null | undefined
    disableLoadingMore?: boolean
}

export type Action =
    | { type: 'left' }
    | { type: 'right' }
    | { type: 'scroll'; index: number }
    | { type: 'resize'; offset: number }
    | { type: 'more'; data: NonNullable<ReleaseMoreRecommendationsQuery['release']> }

export type State = {
    index: number
    native: boolean
    offset: number
    recommendations: ReleaseRecommendationsFragment['recommendations']
}

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'left':
            return { ...state, index: Math.max(0, state.index - state.offset), native: false }
        case 'right':
            return {
                ...state,
                index: Math.min(props.recommendations.totalCount - 1, state.index + state.offset),
                native: false,
            }
        case 'scroll':
            return { ...state, index: action.index, native: true }
        case 'resize':
            return { ...state, offset: action.offset }
        case 'more':
            return {
                ...state,
                recommendations: {
                    totalCount: action.data.recommendations.totalCount,
                    edges: [...state.recommendations.edges, ...action.data.recommendations.edges],
                },
            }
        default:
            return state
    }
}

export function initial(props: Props): State {
    return {
        index: 0,
        native: true,
        offset: 1,
        recommendations: props.recommendations,
    }
}

export function useEffect(props: Props, state: State, dispatch: Dispatcher<Action>): void {
    const {
        discogsId,
        isOffensive,
        disableLoadingMore,
        recommendations: { totalCount },
    } = props
    const { recommendations } = state

    const [loadMore, { data }] = useReleaseMoreRecommendationsLazyQuery()
    const [cursor, setCursor] = React.useState<string | null>(null)

    React.useEffect(
        function () {
            if (
                !disableLoadingMore &&
                !isOffensive &&
                state.index > recommendations.edges.length - state.offset - 2 &&
                totalCount > 0
            ) {
                const last = recommendations.edges[recommendations.edges.length - 1]?.cursor
                setCursor(last)
            }
        },
        [state.index, disableLoadingMore, isOffensive],
    )

    React.useEffect(
        function (): void {
            if (!cursor) {
                return
            }

            void loadMore({
                variables: {
                    discogsId,
                    after: cursor,
                },
            })
        },
        [cursor],
    )

    React.useEffect(
        function () {
            if (data?.release) {
                dispatch({ type: 'more', data: data.release })
            }
        },
        [data],
    )
}

const id = 'release-recommendations'

export const ReleaseRecommendationsRender = addState(RecommendationsUI, reducer, initial, useEffect)

export function ReleaseRecommendations(props: AsyncProps): React.ReactElement | null {
    const { recommendations } = props
    if (!recommendations) {
        return (
            <Section title={<Trans>Recommendations</Trans>} id={id}>
                <Loading height={200} />
            </Section>
        )
    }

    // @ts-expect-error
    return <ReleaseRecommendationsRender {...props} recommendations={recommendations} />
}
