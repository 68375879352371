import * as React from 'react'
import { Trans, t } from '@lingui/macro'

import { ReleaseImagesFragment, Format, ItemType } from '../../api/types'
import { Link } from '../../lib/components/link'
import { NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'
import { ReleaseThumbnail } from '../release-thumbnail'
import { GalleryLink } from '../image-gallery/link'
import { useLocalisation } from '../../lib/i18n'
import { useLogin } from '../../lib/login'
import { explicitMasterContent, explicitReleaseContent } from '../../lib/explicit-contentIds'

import css from './styles.css'

const defaultFormat: Format = {
    name: 'Vinyl',
}

type Props = ReleaseImagesFragment & { itemType?: ItemType }

export function ReleaseImages(props: Props): React.ReactElement {
    const { discogsId, title, formats = [], images, primaryArtists, itemType = null } = props
    const { i18n } = useLocalisation()
    const { loggedIn } = useLogin()

    const releaseTypeCheck = itemType === 'RELEASE' && explicitMasterContent.includes(discogsId)

    const blockForLoggedInUsers = !releaseTypeCheck && explicitReleaseContent.includes(discogsId)

    const image = images.edges[0]?.node
    const artistName = primaryArtists?.[0]?.displayName
    // A release will always have at least one format
    const [format = defaultFormat] = formats

    const alt = image ? t(i18n)`${artistName} - ${title} album cover` : t(i18n)`No image available; add an image`
    if (!image) {
        const add = `/release/${discogsId}/images/update`

        return (
            <div>
                <Link href={add}>
                    <ReleaseThumbnail format={format.name} alt={alt} discogsId={discogsId} />
                </Link>
                <p className={css.add}>
                    <Link href={add}>
                        <Trans>Add An Image</Trans>
                    </Link>
                </p>
            </div>
        )
    }

    if ((image.nsfw && !loggedIn) || blockForLoggedInUsers) {
        return (
            <div>
                <GalleryLink imageId={image.id} className={css.thumbnail}>
                    <NSFWImagePlaceholderSmall showButton />
                    {images.totalCount > 1 && (
                        <p className={css.moreImages}>
                            <Trans>More images</Trans>
                        </p>
                    )}
                </GalleryLink>
            </div>
        )
    }

    return (
        <div className={css.more}>
            <GalleryLink imageId={image.id} className={css.thumbnail}>
                <ReleaseThumbnail
                    image={image.thumbnail}
                    format={format.name}
                    alt={alt}
                    preload
                    lazy={false}
                    discogsId={discogsId}
                />
                {images.totalCount > 1 && (
                    <p className={css.moreImages}>
                        <Trans>More images</Trans>
                    </p>
                )}
            </GalleryLink>
        </div>
    )
}
