/* eslint-disable react/iframe-missing-sandbox */
import * as React from 'react'
import { Trans } from '@lingui/macro'

import { useInteraction } from '../../../lib/components/analytics'

import { Section } from '../section'
import { StreamingService } from '../../../api/types'

import css from './styles.css'

type Props = {
    streamingService?: StreamingService
    height: string
    width: string
}

export function Audio(props: Props): React.ReactElement {
    const { streamingService, height, width } = props
    const track = useInteraction()

    if (!streamingService || !streamingService.id) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>
    }

    function handleLoad(): void {
        track('apple_music_match')
    }

    const src = `https://embed.music.apple.com/us/album/${streamingService.id}?at=1010lSCM&itsct=discogs_music&itscg=30200&ct=web_integration`

    return (
        <Section isOpenByDefault id='audio' title={<Trans>Audio</Trans>}>
            <iframe
                onLoad={handleLoad}
                id='audio-iframe'
                className={css.audio}
                height={height}
                width={width}
                src={src}
                frameBorder='0'
                sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation'
                allow='autoplay *; encrypted-media *;'
                data-hj-allow-iframe=''
            />
        </Section>
    )
}
