import * as React from 'react'
import { Trans } from '@lingui/macro'

import { ReleaseNotesFragment } from '../../api/types'
import { Section } from '../../lib/components/section'

import css from './styles.css'
import { Typography } from '@mui/material'

export function ReleaseNotes(props: ReleaseNotesFragment): React.ReactElement | null {
    const { notes } = props

    if (!notes || notes.html.trim() === '') {
        return null
    }

    return (
        <Section
            id='release-notes'
            title={
                <Typography variant='headLineLarge'>
                    <Trans>Notes</Trans>
                </Typography>
            }
        >
            <Typography variant='bodySmall'>
                <div className={css.notes} dangerouslySetInnerHTML={{ __html: notes.html }} />
            </Typography>
        </Section>
    )
}
